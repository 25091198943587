<template>
  <CHeader fixed with-subheader light>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3" v-if="this.$route.name !== 'Dashboard'">
        <CHeaderNavLink to="/calendar/list">
          {{$t('Все календари')}}
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <a href="/start.html" target="_blank">
        <CButton color="success" class="instruction_link">{{$t('Инструкция: с чего начать')}}</CButton>
      </a>
      <CButton v-if="isAdmin" to="/admin/users" color="info" class="instruction_link">Админка</CButton>
      <span v-if="this.$i18n.locale === 'en'" @click="lang('ru')" class="lang_icon"><CIcon name="cifRu"/></span>
      <span v-if="this.$i18n.locale === 'ru'" @click="lang('en')" class="lang_icon"><CIcon name="cifGb"/></span>
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt
  },
  computed: {
    isAdmin() {
      return !!this.$store.getters.user.admin;
    }
  },
  methods: {
    lang(val) {
      localStorage.setItem('lang', val)
      this.$i18n.locale = val
    }
  }
}
</script>
<style lang="scss">
  .c-header-nav-link {
    text-decoration: underline !important;
  }
  .lang_icon {
    margin-right: 2em;
    cursor: pointer;
    display: inline-block;
    svg {
      width: 1.5em !important;
      height: 1.5em !important;
    }
  }
  .instruction_link {
    margin-right: 2em;
  }
</style>
