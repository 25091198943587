<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          <img
            src="img/avatars/9.jpg"
            class="c-avatar-img "
          />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownItem @click="$router.push('/user/profile')">
      <CIcon name="cil-user" /> {{$t('Профиль')}}
    </CDropdownItem>
    <CDropdownItem @click="logout()">
      <CIcon name="cil-exit-to-app" /> {{$t('Выйти')}}
    </CDropdownItem>
  </CDropdown>
</template>

<script>
export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return {
      itemsCount: 42
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('setUser', null);
      localStorage.removeItem('jwt')
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>
